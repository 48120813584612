





























import { Vue, Component, ModelSync, Prop } from "vue-property-decorator";

import moment from "moment";

@Component
export default class CalendarInput extends Vue {
    @ModelSync("dateValue", "change", { type: String })
    readonly date?: string;
    @Prop({ type: Array, default: [], required: false })
    readonly availableDates!: Array<string>;
    @Prop({ type: String, default: "date", required: false })
    readonly type!: string;

    /**
     * Computed
     */
    get formatDate() {
        if (this.date) {
            return moment(this.date).format("DD/MM/YYYY");
        }
        return "";
    }

    dateUpdate() {
        this.$emit("on-date-updated");
    }
}
